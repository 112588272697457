import { ApiClient, TopupWalletVoucher, Wallet } from "@t16t/sdk"

type ApiResponse<T> = {
    errorMessage: string | undefined;
    data: T | undefined;
}

export class BillingService {

    async fetchWallet(): Promise<ApiResponse<Wallet>> {
        const { data, error } = await ApiClient.getClient().GET('/api/v1/wallet');

        return {
            errorMessage: error? 'Something went wrong' : '',
            data
        }
    }

    async topupWallet(data: TopupWalletVoucher): Promise<ApiResponse<void>> {
        const { error } = await ApiClient.getClient().POST('/api/v1/wallet', {
            body: data,
        })

        return {
            errorMessage: error ? 'Something went wrong' : '',
            data: undefined
        }
    }
}